import axios from "axios"

export const postNewAppointment = async (data) => {
        //{ message: "Appointment created successfully", appointment: {id: 1, ...} }
    const response = await axios.post('https://wtm-api-j8i0.onrender.com/api/appointments', data)
    return response.data
}

export const getAppointments = async () => {    
    const response = await axios.get('https://wtm-api-j8i0.onrender.com/api/appointments', {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
    return response.data
}

export const logIn = async (data) => {
    const response = await axios.post('https://wtm-api-j8i0.onrender.com/api/users/login', data)
    return response.data
}

export const signUp = async (data) => {
    //pass it email
    const response = await axios.post('https://wtm-api-j8i0.onrender.com/api/users/save-email', data)
    //returns nothing from the server
    console.log('response', response);
    
    return response
}

export const verifyCode = async (data) => {
    //pass it verfication code
    const response = await axios.post('https://wtm-api-j8i0.onrender.com/api/users/verify-code', data)
    //token is returned
    return response.data
}

export const registerUser = async (data) => {
    //pass it password and temp token
    const response = await axios.post('https://wtm-api-j8i0.onrender.com/api/users/register', data)
    //user is returned
    return response.data
}

export const updateAppointmentStatus = async (status, id) => {
    const response = await axios.patch(`https://wtm-api-j8i0.onrender.com/api/appointments/${id}/update-status`, {status}, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }})
        return response.data
    }

export const getUnavailableTimes = async (requestedDate) => {
    const response = await axios.get(`https://wtm-api-j8i0.onrender.com/api/appointments/unavailable-times/?requestedDate=${requestedDate}`)
    console.log('timeslot response', response);
    
    return response.data
}
        

//Appointment Object

// {
//     fullName: "John Doe",
//     email: "test@test.com",
//     phone: "123-456-7890",
//     date: "01/01/2025",
//     time: "10:00 AM",
//     message: "This is a test message"

// }