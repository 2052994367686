import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,

  Paper,

} from '@mui/material'

import { displayDayAndDateString } from '../services/dateService';
import { COLOR } from '../siteStyle';
export const AppointmentTable = ({ appointments, setSelectedAppointment,  }) => {
    return (
        <TableContainer component={Paper} sx={{ borderRadius: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><b>Full Name</b></TableCell>
                <TableCell><b>Email</b></TableCell>
                <TableCell><b>Phone</b></TableCell>
                <TableCell><b>Service</b></TableCell>
                <TableCell><b>Date Requested</b></TableCell>
                <TableCell><b>Status</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appointments.map((appointment, index) => (
                <TableRow
                  key={index}
                  hover
                  sx={{ cursor: "pointer" }}
                  onClick={() => setSelectedAppointment(appointment)}
                >
                  <TableCell>{appointment.fullname}</TableCell>
                  <TableCell>{appointment.email}</TableCell>
                  <TableCell>{appointment.phone}</TableCell>
                  <TableCell>{appointment.service}</TableCell>
                  <TableCell>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {displayDayAndDateString(appointment.requestedDate, 'long')} at {appointment.requestedTime}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        color:
                          appointment.status === "Pending"
                            ? "orange"
                            : appointment.status === "Confirmed"
                            ? "green"
                            : COLOR.danger,
                      }}
                    >
                      {appointment.status}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    )
}