import React from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Chip,
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { humanizeDate } from "../services/dateService";
import { useSelector } from "react-redux";
import { getUnavailableTimes } from "../services/api";
import { selectFormData } from "../services/formSlice";
import { COLOR } from "../siteStyle";

const AppointmentBookingForm = ({ handleChange, submitted, handleSubmit, error}) => {
const formData = useSelector(selectFormData)

  const [unavailableTimes, setUnavailableTimes] = React.useState([]);

  const availableTimes = [
    "10:00 AM", "10:30 AM",
    "11:00 AM", "11:30 AM",
    "12:00 PM", "12:30 PM",
    "1:00 PM", "1:30 PM",
    "2:00 PM", "2:30 PM",
    "3:00 PM", "3:30 PM",
    "4:00 PM", "4:30 PM",
    "5:00 PM", "5:30 PM",
    "6:00 PM"
];


const handleOnAccept = async (newValue) => {
  // console.log('ON ACCEPT', newValue);
  const response = await getUnavailableTimes(humanizeDate(newValue))
  setUnavailableTimes(response.unavailableTimeSlots)
  
}

  return (
    <Box sx={{display: 'flex'}}>
    <Box
      sx={{
        maxWidth: 600,
        mx: "auto",
        mt: 4,
        p: 3,
        border: "1px solid #ccc",
        borderRadius: 2,
        boxShadow: 2,
        backgroundColor: "#fff",
      }}
    >
      <Typography variant="h5" gutterBottom>
        Book an Appointment
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Full Name"
              variant="outlined"
              fullWidth
              value={formData.fullname}
              onChange={(e) => handleChange("fullname", e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              type="email"
              variant="outlined"
              fullWidth
              value={formData.email}
              onChange={(e) => handleChange("email", e.target.value)}
              // required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Phone"
              type="tel"
              variant="outlined"
              fullWidth
              value={formData.phone}
              onChange={(e) => handleChange("phone", e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
              // shouldDisableDate={checkIfDateAvaiable}
                disablePast
                dateFormat="MM-DD-YYYY"
                label="Appointment Date"
                value={formData.requestedDate}
                onAccept={handleOnAccept}
                onChange={(newValue) =>{
                  const formattedDate = humanizeDate(newValue)                
                  handleChange("requestedDate", formattedDate)}
                }
                renderInput={(params) => (
                  <TextField {...params} fullWidth required />
                )}
              />
            </LocalizationProvider>
          </Grid>
            <Box paddingX={2} mt={2}>
              { formData.requestedDate &&
                availableTimes.map((time) => {
                  let matchedTimes = formData.requestedTime === time 

                  if(unavailableTimes.includes(time)){
                    return <Chip
                    label={time}
                    sx={{margin: 1}}
                    // deleteIcon={<DoneIcon />}
                    disabled
                  />
                  }
                  return <Chip
                  label={time}
                  clickable
                  onClick={() => {handleChange("requestedTime", time)
                    console.log("CLICKED", {time, formData: formData.requestedTime})
                  }}
                  variant="outlined"
                  sx={{margin: 1, backgroundColor: matchedTimes ? COLOR.secondary : "", color: matchedTimes ? "white" : ""}}
                  
                />
                })
              }
              </Box>
            {/* </FormControl> */}
          <Grid item xs={12}>
            <TextField
              label="Service - what service(s) are you interested in?"
              variant="outlined"
              fullWidth
              value={formData.service}
              onChange={(e) => handleChange("service", e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Message - tell us a little more"
              variant="outlined"
              fullWidth
              value={formData.message}
              onChange={(e) => handleChange("message", e.target.value)}
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={submitted}
            >
             { submitted ? <CircularProgress /> : "Submit"}
            </Button>
          </Grid>
        </Grid>
      </form>
      {error?.isError && <Typography color="error">{error?.message}</Typography>}
    </Box>
    </Box>

    
  );
};

export default AppointmentBookingForm;


//TO DO: Add indicator to show which time has been selected