import React, { useContext } from 'react'

import { Box, Typography } from '@mui/material'
import { COMMON_SITE_DATA } from '../siteData'
import { COLOR } from '../siteStyle'
import { LanguageContext } from '../App'
import { Link, useNavigate } from 'react-router-dom'

const Column = ({ title, content }) => {
  return (
    <Box display={'flex'} justifyContent={'flex-end'}>
      <Typography marginRight={2}>{title}</Typography>
      <Typography fontWeight={700}>{content}</Typography>
    </Box>
  )
}

export const Footer = () => {
  const { siteData, setSiteData } = useContext(LanguageContext)

  const navigate = useNavigate()

  return (
    //Make Box compoenent stick to bottom of screen
    <Box sx={{ width: '100%', marginTop: 35 }}>
      <Box
        sx={{
          backgroundColor: COLOR.accent,
          py: 5,
          px: 5,
          color: COLOR.white,
          textAlign: 'right',
        }}
      >
        <Box marginBottom={2} pt={5}>
          <Typography fontWeight={700}>
            {siteData.contactSection.contactItems.address.street}
          </Typography>
          <Typography fontWeight={700}>
            {siteData.contactSection.contactItems.address.cityStateZip}
          </Typography>
        </Box>
        {siteData.contactSection.contactItems.phone.map((item) => (
          <Column title={item.title} content={item.content} key={item.content}/>
        ))}
        <Column
          title={siteData.contactSection.contactItems.email.title}
          content={siteData.contactSection.contactItems.email.content}
        />
        <Column
          title={siteData.contactSection.contactItems.wechat.title}
          content={siteData.contactSection.contactItems.wechat.content}
        />
      </Box>
      <Box
        flex
        textAlign={'center'}
        sx={{
          py: 5,
          backgroundColor: COLOR.secondary,
          color: COLOR.white,
        }}
        onClick={() => navigate('/login')}
      >
        {COMMON_SITE_DATA.copyRightText}
      </Box>
    </Box>
  )
}
