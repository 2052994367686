import React, { useEffect, useState } from 'react'
import { Box, TextField, Button, Typography, Grid, CircularProgress, Divider } from '@mui/material'
import { authenticateUser, selectAuthStatus, selectError, setError, signUserOut } from '../services/appSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { logIn, registerUser, signUp, verifyCode } from '../services/api'
import PopUpAlert from '../components/PopUpAlerts'
import { Email } from '@mui/icons-material'
import { set } from 'date-fns'
import { selectErrorMessage } from '../services/formSlice'

const SignUp = () => {
  const isSignedIn = useSelector(selectAuthStatus)
  const navigate = useNavigate()
  const dispatch = useDispatch()
const errorMessage = useSelector(selectErrorMessage)
  const [formData, setFormData] = useState({
    formName: 'email',
    email: '',
    password: '',
    verificationCode: '',
    tempToken: ''
  })

  const errors = useSelector(selectError)

  const [submitted, setSubmitted] = useState(false)

  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

//   useEffect(() => {
//     const login = async () => {
//       const token = localStorage.getItem('token')
//       if (token && !isSignedIn) {
//         return await logIn({token})
//           .then((data) => dispatch(authenticateUser(token)))
//           .catch((err) => dispatch(signUserOut()))
//       } else {
//         return 
//       }
//     }

//     login()
//   }, [])

  const handleSubmit = async (event, type) => {
    event.preventDefault()

    setSubmitted(true);

    if (type === 'email') {
      return await signUp({email: formData.email})
      .then((data) => {
        setSubmitted(false)
      })
      .then((data) => {
        setFormData({...formData, formName: 'verficationCode'})
      })
      .catch((err) => {
        setSubmitted(false)
        dispatch(setError({isError: true, message: err.response.data.error}))
      })
    }

    if (type === 'verificationCode') {
        return await verifyCode({email: formData.email, code: formData.verificationCode})
        .then((data) => {
            setSubmitted(false)
            return data
          })
        .then((data) => {
          setFormData({...formData, tempToken: data.tempToken, formName: 'register'})
        })
        .catch((err) => dispatch(setError({isError: true, message: "Invalid code"})))
      } 

      if (type === 'register') {
        return await registerUser({password: formData.password, tempToken: formData.tempToken})
        .then((data) => {
            setSubmitted(false)
            return data
          })
        // .then((data) => {
        //   setFormData({...formData, tempToken: data.tempToken, formName: 'register'})
        // })
        .then(() => navigate('/login'))
        .catch((err) => dispatch(setError({isError: true, message: "Invalid code"})))
      } 

  }

  useEffect(() => {
    if (isSignedIn) {
      return navigate('/auth/appointments')
    }
    return
  }, [isSignedIn])

  const loadingButton = (buttonName) => 
    submitted ? <CircularProgress sx={{display: 'block', margin: 'auto'}} /> : <Button id="pop" type="submit" variant="contained" color="primary" fullWidth>{buttonName}</Button>


  const emailInput = (
    <form onSubmit={(e) => handleSubmit(e, 'email')}>
        <Typography variant="h6" gutterBottom textAlign="center">Enter your email</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Email"
              type="email"
              variant="outlined"
              fullWidth
              value={formData.email}
              onChange={(e) => handleChange('email', e.target.value)}
              required
            />
          </Grid>
          
          <Grid item xs={12}>
            {loadingButton('Next')}
          </Grid>
        </Grid>
      </form>
  )

  const verificationCodeInput = (
    <form onSubmit={(e) => handleSubmit(e, 'verificationCode')}>
        <Typography variant="h6" gutterBottom textAlign="center">Enter your verification code</Typography>

        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                label="Verification Code"
                type="number"
                variant="outlined"
                fullWidth
                value={formData.verificationCode}
                onChange={(e) => handleChange('verificationCode', e.target.value)}
                required
                />
            </Grid>
            
            <Grid item xs={12}>
            {loadingButton('Next')}
            </Grid>
        </Grid>
      </form>
  )

  const passwordInput = (
    <form onSubmit={(e) => handleSubmit(e, 'register')}>
        <Typography variant="h6" gutterBottom textAlign="center">Create a password</Typography>

        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    value={formData.password}
                    onChange={(e) => handleChange('password', e.target.value)}
                    required
                />
            </Grid>
            
            <Grid item xs={12}>
               {loadingButton('Sign Up')}
            </Grid>
        </Grid>
      </form>
  )



  return (
    <Box
      sx={{
        maxWidth: 400,
        mx: 'auto',
        mt: 25,
        mb: 40,
        p: 3,
        border: '1px solid #ccc',
        borderRadius: 2,
        boxShadow: 2,
        backgroundColor: '#fff',
      }}
    >
      
      <Typography variant="h5" gutterBottom textAlign="center">
        Sign Up for an Account
      </Typography>
      {
        formData.formName === 'email' ? emailInput : formData.formName === 'verficationCode' ? verificationCodeInput : passwordInput
      }
      {errors?.isError && <PopUpAlert message={errors.message} severity="error" />}
     {errorMessage && <Typography>{errorMessage}</Typography>}
    </Box>
  )
}

export default SignUp
