import React, { createContext, useEffect, useState } from 'react'
// import logo from "./logo.svg";
import './App.css'
import Navibar from './components/Navibar.js'
import { Route, Routes } from 'react-router-dom'
import { Home } from './pages/home.js'
import { Footer } from './components/Footer.js'
import { AboutPage } from './pages/about.js'
import { ServicesPage } from './pages/services.js'
import { ContactPage } from './pages/contact.js'
import { ENGLISH_DATA, CHINESE_DATA } from './siteData.js'
import { LanguageFAB } from './components/LanguageFAB.js'
import bannerImg from './assets/images/bannerImg.jpg'
import { COLOR } from './siteStyle'
import { Box } from '@mui/material'
import { Login, Phone } from '@mui/icons-material'
import { PhoneFAB } from './components/PhoneFAB.js'
import AppointmentPage from './pages/appointment.js'
import { AuthRoute } from './services/AuthRoute.js'
import LoginPage from './pages/login.js'
import AppointmentList from './pages/auth/appointmentList.js'
import SignUp from './pages/signUp.js'
export const LanguageContext = createContext('')

function App() {
  const [language, setLanguage] = useState('english')
  const [siteData, setSiteData] = useState(ENGLISH_DATA)

  const handleChangeLanguage = () =>
    setLanguage(language === 'english' ? 'chinese' : 'english')

  useEffect(() => {
    // console.log('Firing useEffect in App.js. Current Lanuage: ', language)
    if (language === 'chinese') {
      setSiteData(CHINESE_DATA)
    } else {
      setSiteData(ENGLISH_DATA)
    }
  }, [language])

  return (
    <div
      style={{
        position: 'relative',
        zIndex: 2,
      }}
    >
      {/* <Box
        sx={{
          position: 'absolute',
          left: { xs: -20, md: -55 },
          top: { xs: 450, md: 250 },
          // top: '30%',
          backgroundColor: COLOR.primary,
          backgroundImage: `url(${bannerImg})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPositionY: 'bottom',
          backgroundPositionX: 'left',
          height: { xs: 250, md: 700 },
          width: { xs: 250, md: 700 },
          zIndex: -1,
        }}
      ></Box>
      <Box
        sx={{
          position: 'absolute',
          right: { xs: 0, md: -65 },
          bottom: { xs: 150, md: 0 },
          backgroundColor: COLOR.primary,
          backgroundImage: `url(${bannerImg})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPositionY: 'top',
          backgroundPositionX: 'right',
          height: { xs: 250, md: 700 },
          width: { xs: 250, md: 700 },
          zIndex: -1,
        }}
      ></Box> */}

      <LanguageContext.Provider
        value={{ language, handleChangeLanguage, siteData }}
      >
        <Navibar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/book-appointment" element={<AppointmentPage />} />
          <Route element={<AuthRoute />}>
            <Route path="/auth/appointments" element={<AppointmentList />} />
          </Route>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="*" element={<h1>404 - Not Found</h1>} />
        </Routes>

        <Footer />
        <Box position={'fixed'} bottom={'5vh'} right={20}>
          <PhoneFAB />
          <LanguageFAB />
        </Box>
      </LanguageContext.Provider>
    </div>
  )
}

export default App
