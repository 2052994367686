//create appSlice
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { updateAppointmentStatus } from './api'
const appSlice = createSlice({
  name: 'app',
  initialState: {
    auth: { isSignedIn: false, userData: null, token: null },
    appointments: [],
    filter: {name: 'Pending', number: 0},
    error: {isError: false, message: ""}
  },
  reducers: {
    signUserOut: (state) => { state.auth = { isSignedIn: false, userData: null, token: null } },
    authenticateUser: (state, action) => {
      state.auth = { isSignedIn: true, token: action.payload }
    },
    loadAppointments: (state, action) => {
      state.appointments = action.payload
    },
    updateAppointment: (state, action) => {
        state.appointments = state.appointments.map((appointment) => {
            if (appointment._id === action.payload._id) {
                return action.payload
            }
            return appointment
        })
    },
    setFilter: (state, action) => {
        state.filter = action.payload
    },
    setError: (state, action) => {
        state.error = action.payload
    }
  },
})

export const { loadAppointments, authenticateUser, updateAppointment, setFilter, signUserOut, setError } = appSlice.actions

export const selectFilter = (state) => state.app?.filter
export const selectAppointments = (state) => state.app?.appointments
export const selectAuthStatus = (state) => state.app?.auth.isSignedIn
export const selectToken = (state) => state.app?.auth.token
export const selectPendingAppointments = 
  createSelector(
    [selectAppointments],
    (appointments) =>
      {console.log('appointments create selector', appointments);
       return appointments.filter((appointment) => appointment.status === 'Pending')}
  )
export const selectFitleredAppointments = createSelector(
    [selectAppointments, selectFilter], (appointments, filter) => {
        if (filter.name === 'Pending') {
            return appointments.filter((appointment) => appointment.status === 'Pending')
        } else if (filter.name === 'Completed') {
            return appointments.filter((appointment) => appointment.status === 'Completed')  
        } else {
            return appointments.filter((appointment) => appointment.status === 'Rejected' || appointment.status === 'Absent')
        }
    }
)

export const selectError = (state) => state.app?.error

export default appSlice.reducer