//create formSlice
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { updateAppointmentStatus } from './api'
import { humanizeDate } from './dateService'

const INITIAL_FORM_STATE = {
        fullname: "",
        requestedDate: null,
        requestedTime: "",
        email: "",
        phone: "",
        message: "",  
        service: ""
}

const formSlice = createSlice({
  name: 'formSlice',
  initialState: {
    formName: '',   
    formData: INITIAL_FORM_STATE,
    error: {isError: false, message: ""}
  },
  reducers: {
    setFormData: (state, action) => { 
        state.formData =  action.payload 
    },
   
    setFormName: (state, action) => {
        state.filter = action.payload
    },
    clearFormData: (state) => {
        console.log('clearing form data');
        
        state.formData = INITIAL_FORM_STATE
    },
    setError: (state, action) => {
        state.error = action.payload
    }
  },
})

export const { setFormData, setFormName, setError, clearFormData } = formSlice.actions

export const selectFormData = (state) => state.form?.formData
export const selectErrorMessage = state => state.form?.error.message

export default formSlice.reducer