export const humanizeDate = (date) => {
  
  return new Date(date).toLocaleDateString('en-US')
}

export const displayDayString = (date, format) => {
  return `${new Date(date).toLocaleDateString('en-US', { weekday: format ? format : 'short' })}`
}

export const displayDayAndDateString = (date, format) => {
  return `${new Date(date).toLocaleDateString('en-US', { weekday: format ? format : 'short' })} - ${new Date(date).toLocaleDateString('en-US')}`
}