import React, { useState } from "react";

import AppointmentBookingForm from '../components/AppointmentForm'
import { postNewAppointment } from "../services/api";
import { useNavigate } from "react-router-dom";
import AppointmentConfirmationModal from "../components/appointmentSummaryModal";
import { useDispatch, useSelector } from "react-redux";
import { selectError, setError } from "../services/appSlice";
import { clearFormData, selectFormData, setFormData } from "../services/formSlice";
import CircularProgress from '@mui/material/CircularProgress';
import { display } from "@mui/system";
import { Box } from "@mui/material";


const AppointmentPage = () => {
    const [submitted, setSubmitted] = useState(false);
    const [open, setOpen] = useState(false);
    const [newAppointment, setNewAppointment] = useState(null);
    const formData = useSelector(selectFormData)
    const error = useSelector(selectError)

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const handleChange = (field, value) => {
        dispatch(setFormData({...formData, [field]: value}))
      };
    
      //*FOLLOW-UP: Add pop up alert if something goes wrong
      const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitted(true)
        dispatch(setError({isError: false, message: ''}))

        return await postNewAppointment(formData)
            .then((data) => setNewAppointment(data.appointment))
            .then(() => setSubmitted(false))
            .then(() => setOpen(true))
            .then(() => dispatch(clearFormData()))
            .catch((err) => {
              dispatch(setError({isError: true, message: 'This appointment time is full. Please select another time.'}))
              setSubmitted(false)
            }
             
            );
      };

      const onClose = () => {
        setOpen(false);
        navigate('/')
      }

    return (
        <>
            <AppointmentBookingForm error={error} submitted={submitted} handleChange={handleChange} handleSubmit={handleSubmit}/>
            <AppointmentConfirmationModal open={open} appointment={newAppointment} onClose={onClose}/>
        </>
    )
}

export default AppointmentPage