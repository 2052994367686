import React, { useEffect, useState } from 'react'
import { Box, TextField, Button, Typography, Grid } from '@mui/material'
import { authenticateUser, selectAuthStatus, selectError, setError, signUserOut } from '../services/appSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { logIn } from '../services/api'
import PopUpAlert from '../components/PopUpAlerts'

const LoginPage = () => {
  const isSignedIn = useSelector(selectAuthStatus)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })

  const errors = useSelector(selectError)

  const [submitted, setSubmitted] = useState(false)

  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  useEffect(() => {
    const login = async () => {
      const token = localStorage.getItem('token')
      if (token && !isSignedIn) {
        return await logIn({token})
          .then((data) => dispatch(authenticateUser(token)))
          .catch((err) => dispatch(signUserOut()))
      } else {
        return 
      }
    }

    login()
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()
    return await logIn(formData)
    .then((data) => {
      localStorage.setItem('token', data.token)
      return data
    })
    .then((data) => dispatch(authenticateUser(data.token)))
    .then(() => dispatch(setError({isError: false, message: ""})))
    .catch((err) => dispatch(setError({isError: true, message: "Invalid email or password"})))
  }

  useEffect(() => {
    if (isSignedIn) {
      return navigate('/auth/appointments')
    }
    return
  }, [isSignedIn])

  return (
    <Box
      sx={{
        maxWidth: 400,
        mx: 'auto',
        mt: 25,
        mb: 40,
        p: 3,
        border: '1px solid #ccc',
        borderRadius: 2,
        boxShadow: 2,
        backgroundColor: '#fff',
      }}
    >
      
      <Typography variant="h5" gutterBottom textAlign="center">
        Login
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Email"
              type="email"
              variant="outlined"
              fullWidth
              value={formData.email}
              onChange={(e) => handleChange('email', e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              value={formData.password}
              onChange={(e) => handleChange('password', e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button id="pop" type="submit" variant="contained" color="primary" fullWidth>
              Login
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" color="primary" fullWidth component={Link} to="/sign-up">
              Create an Account
            </Button>
          </Grid>
        </Grid>
      </form>
      {errors?.isError && <PopUpAlert message={errors.message} severity="error" />}
      {submitted && (
        <Typography
          variant="body1"
          color="success.main"
          sx={{ mt: 2, textAlign: 'center' }}
        >
          Login successful!
        </Typography>
      )}
    </Box>
  )
}

export default LoginPage
