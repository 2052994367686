import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
  Button,
  Modal,
} from "@mui/material";

const AppointmentConfirmationModal = ({
  open,
  onClose,
  appointment,
  onConfirm,
  onEdit,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: "90%", // Responsive width
          maxWidth: 600, // Limit max size
          margin: "auto",
          mt: "10vh", // Center vertically
          borderRadius: 2,
          boxShadow: 4,
          backgroundColor: "#fff",
          outline: "none",
          textAlign: "center", // Center all text
          p: 3,
        }}
      >
       

        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 1 }}
        >
          Appointment Confirmation
        </Typography>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          sx={{ mb: 3 }}
        >
          Please review your appointment details.
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: "bold",
            color: "primary.main",
            fontSize: "2rem",
          }}
        >
          {appointment?.requestedDate} @ {appointment?.requestedTime}
        </Typography>

        <Box component={Grid} container direction="row" columnSpacing={{xs: 1, md: 2}} sx={{justifyContent: "center", alignItems: "baseline"}} >
            <Grid item lg={5} sx={{textAlign: 'right'}}>
                <Typography sx={{ fontSize: "1.1rem", mb: 1 }}>
                    <b>Name:</b>
                </Typography>
                <Typography sx={{ fontSize: "1.1rem", mb: 1 }}>
                    <b>Email:</b>
                </Typography>
                <Typography sx={{ fontSize: "1.1rem", mb: 1 }}>
                    <b>Phone:</b> 
                </Typography>
                <Typography sx={{ fontSize: "1.1rem", mb: 1 }}>
                    <b>Service:</b> 
                </Typography>
                <Typography sx={{ fontSize: "1.1rem", mb: 1 }}>
                    <b>Additional Comments:</b>
                </Typography>
            </Grid>
            <Grid item lg={7} sx={{textAlign: 'left'}}>
                <Typography sx={{ fontSize: "1.1rem", mb: 1 }}>
                    {appointment?.fullname}
                </Typography>
                <Typography sx={{ fontSize: "1.1rem", mb: 1 }}>
                    {appointment?.email}
                </Typography>
                <Typography sx={{ fontSize: "1.1rem", mb: 1 }}>
                    {appointment?.phone}
                </Typography>
                <Typography sx={{ fontSize: "1.1rem", mb: 1 }}>
                    {appointment?.service}
                </Typography>
                <Typography sx={{ fontSize: "1.1rem", mb: 1 }}>
                    {appointment?.comment || "No additional comments."}
                </Typography>
            </Grid>
          
        </Box>

        <Divider sx={{ my: 2 }} />

        <Grid container spacing={2} justifyContent="center">
            <Button
              variant="outlined"
              color="error"
              onClick={onClose}
              size="large"
            >
              Close
            </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AppointmentConfirmationModal;
