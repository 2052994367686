
import React from 'react'
import {
  Box,
  Typography,
  Button,
  Modal,
  Divider,
} from '@mui/material'
import { COLOR } from '../siteStyle'

export const AppointmentDetailModal = ({ handleClose, selectedAppointment, handleStatusUpdate }) => {
    return (
      <Modal open={!!selectedAppointment} onClose={handleClose}>
      <Box
        sx={{
          width: "90%",
          maxWidth: 500,
          mx: "auto",
          mt: "10vh",
          p: 3,
          backgroundColor: "white",
          boxShadow: 24,
          borderRadius: 2,
          outline: "none",
        }}
      >
        {selectedAppointment && (
          <>
            <Typography
              variant="h5"
              gutterBottom
              color="primary"
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              Appointment Details
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography>
              <b>Full Name:</b> {selectedAppointment.fullname}
            </Typography>
            <Typography>
              <b>Date & Time:</b>{" "}
              {selectedAppointment.requestedDate} at{" "}
              {selectedAppointment.requestedTime}
            </Typography>
            <Typography>
              <b>Email:</b> {selectedAppointment.email}
            </Typography>
            <Typography>
              <b>Phone:</b> {selectedAppointment.phone}
            </Typography>
            <Typography>
              <b>Service:</b> {selectedAppointment.service}
            </Typography>
            <Typography>
              <b>Notes:</b> {selectedAppointment.notes || "N/A"}
            </Typography>
            <Typography>
              <b>Admin Notes:</b> {selectedAppointment.adminNotes || "N/A"}
            </Typography>
            <Typography>
                  <b>Status:</b>{" "}
                  <span
                    style={{
                      color:
                      selectedAppointment.status === "Pending"
                          ? "orange"
                          : selectedAppointment.status === "Confirmed"
                          ? "green"
                          : COLOR.danger,
                      fontWeight: "bold",
                    }}
                  >
                    {selectedAppointment.status}
                  </span>
                </Typography>
            <Divider sx={{ my: 2 }} />
              {new Date() > new Date(selectedAppointment.requestedDate) && (
              <>
                <Button
                variant="contained"
                color="success"
                fullWidth
                sx={{ mb: 1 }}
                onClick={() => handleStatusUpdate('Completed', selectedAppointment._id)}
                >
                Complete
                </Button>
                <Button
                variant="contained"
                color="warning"
                fullWidth
                sx={{ mb: 1 }}
                onClick={() => handleStatusUpdate('Absent', selectedAppointment._id)}
                >
                'No Show'
                </Button>
                {/* <Button
                variant="contained"
                color="error"
                fullWidth
                sx={{ mb: 1 }}
                onClick={() => handleDelete(selectedAppointment.id)}
                >
                Delete
                </Button> */}
              </>
              )}
              {new Date() < new Date(selectedAppointment.requestedDate) && ( <Button
                variant="contained"
                color="error"
                fullWidth
                sx={{ mb: 1 }}
                onClick={() => handleStatusUpdate('Rejected', selectedAppointment._id)}
                >
                Reject
                </Button>)}
            {/* <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleClose}
            >
              Close
            </Button> */}
          </>
        )}
      </Box>
    </Modal>
    )
}
