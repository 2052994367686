// src/store.js
import { configureStore } from '@reduxjs/toolkit'
import appReducer from './services/appSlice'
import formReducer from './services/formSlice'
export default configureStore({
  reducer: {
    app: appReducer,
    form: formReducer
  },
})
