import { Button } from "@mui/material"
import { Link } from "react-router-dom"
import { COLOR } from "../siteStyle"
import { SiteButtonBase } from "./SiteButtonBase"

const BookAppointmentButton = () => {
    return (
        <SiteButtonBase  variant="contained" size="large" component={Link} to='/book-appointment'>Book Appointment</SiteButtonBase>
    )
}

export default BookAppointmentButton