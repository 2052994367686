import { Box, Divider, Grid, Paper, Typography } from '@mui/material'

const ContactItem = ({ icon, text, text2 }) => {
  return (
    <Box display={'flex'} alignItems={'center'} my={2}>
      {icon}
      <Typography variant="h7">
        {text} <br /> {text2}
      </Typography>
    </Box>
  )
}
export const ContactCard = ({
  hoursTitle,
  workDays,
  workHours,
  street,
  cityStateZip,
  phone,
  wechat,
  email
}) => {
  return (
    <Paper sx={{ marginTop: 5, p: 5 }}>
      <Grid
        container
        columns={16}
        columnGap={2}
        // flexDirection={{ xs: 'column', md: 'row' }}
      >
        <Grid
          xs={16}
          md={6}
          component={Box}
          display={'flex'}
          textAlign={'center'}
          flexDirection={'column'}
          justifyContent={'center'}
        >
          <Typography variant={'h6'} marginBottom={2}>{hoursTitle}</Typography>
          <Typography fontWeight={700}>{workDays}</Typography>
          <Typography fontWeight={700}>{workHours}</Typography>
        </Grid>
        <Divider
          flexItem
          orientation="vertical"
          sx={{ display: { xs: 'none', md: 'flex' } }}
        />
        <Grid
          xs={16}
          md={7}
          component={Box}
          display={'flex'}
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-end' },
            alignItems: 'center',
          }}
        >
          <Box sx={{fontWeight: 700}}>
            <ContactItem
              icon={
                <i
                  class="fa-solid fa-location-dot"
                  style={{ marginRight: 22 }}
                ></i>
              }
              text={street}
              text2={cityStateZip}
            />
            {
              phone.map(item => <ContactItem
              key={item.content}
                icon={
                  <i class="fa-solid fa-phone" style={{ marginRight: 17 }}></i>
                }
                text={item.content}
              />)
            }
            <ContactItem
              icon={
                <i class="fa fa-envelope" style={{ marginRight: 17 }}></i>
              }
              text={email}
            />
            <ContactItem
              icon={
                <i class="fa-brands fa-weixin" style={{ marginRight: 17 }}></i>
              }
              text={wechat}
            />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}
