import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Modal,
  Paper,
  Grid,
  Card,
  CardContent,
  Divider,
  Tab,
  Tabs,
  useMediaQuery,
} from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout';
import { styled } from '@mui/system'
import { getAppointments, updateAppointmentStatus } from '../../services/api';
import { loadAppointments, selectAppointments, selectFilter, selectFitleredAppointments, selectPendingAppointments, setFilter, signUserOut, updateAppointment } from '../../services/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { displayDayAndDateString } from '../../services/dateService';
import { AppointmentTable } from '../../components/AppointmentTable';
import { AppointmentTableMobile } from '../../components/AppointmentTableMobile';
import { AppointmentDetailModal } from '../../components/AppointmentDetailModal';
import { COLOR } from '../../siteStyle';
import { use } from 'react';
import { useNavigate } from 'react-router-dom';
const ExpandableTableRow = styled(TableRow)(({ theme }) => ({
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: 'gray.100',
    transform: 'scale(1.02)',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    zIndex: 1,
  },
}))

const AppointmentList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const appointments = useSelector(selectFitleredAppointments)
  const selectAppointmentFilter = useSelector(selectFilter)

  useEffect(() => {
    const fetchAllAppointments = async () => {
      const data = await getAppointments()
      dispatch(loadAppointments(data.appointments))
      // setAppointments(data)
    }
    fetchAllAppointments()
  }, [])

  const isMobile = useMediaQuery('(max-width:600px)')
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  console.log("selectAppointmentFilter", selectAppointmentFilter);
  
  // Function to close the modal
  const handleClose = () => setSelectedAppointment(null);

  const handleStatusUpdate = async (status, _id) => {
    await updateAppointmentStatus(status, _id)
    .then((data) => {
      dispatch(updateAppointment(data.appointment))
    })
    .then(() => setSelectedAppointment(null))
  }
  const handleOnChange = (event, filter) => {
    event.preventDefault();
    const filters = ['Pending', 'Completed', 'Reject/Absent'];
    dispatch(setFilter({name: filters[filter], number: filter}));
  }

  const handleSignOut = () => {
    localStorage.removeItem('token')
    dispatch(signUserOut())
    navigate('/login')
  }

  return (
    <Box sx={{ maxWidth: "100%", mx: "auto", p: 2, mb: 100 }}> 
      <Box display="flex" justifyContent={'flex-end'}><Button variant="contained" onClick={handleSignOut}>Sign Out</Button></Box>
      <Typography
        variant="h5"
        sx={{ mb: 2, textAlign: "center", fontWeight: "bold" }}
      >
        Appointment Requests
      </Typography>
      <Tabs
        value={selectAppointmentFilter?.number}
        onChange={handleOnChange}
        centered
        aria-label="scrollable auto tabs example"
      >
        <Tab label="Pending" />
        <Tab label="Completed" />
        <Tab label="Reject/Absent" />
      </Tabs>

      {/* Table Layout for Desktop */}
      {!isMobile ? (
        <AppointmentTable appointments={appointments} setSelectedAppointment={setSelectedAppointment} />
      ) : (
        <AppointmentTableMobile appointments={appointments} setSelectedAppointment={setSelectedAppointment} />
      )}

     <AppointmentDetailModal selectedAppointment={selectedAppointment} handleClose={handleClose} handleStatusUpdate={handleStatusUpdate}/>
    </Box>
  );
}

export default AppointmentList
