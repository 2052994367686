import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import { selectAuthStatus } from './appSlice'

export const AuthRoute = () => {
  const isSiteSignedIn = useSelector(selectAuthStatus)
  const navigate = useNavigate()
  
  useEffect(() => {
    if (!isSiteSignedIn) {
      return navigate('/login')
    }
    return
  }, [])

  return <Outlet />
}
