import React, { useEffect, useState } from 'react'
import {
  Typography,
  Grid,
  Paper,
  Card,
  CardContent,
  Divider
} from '@mui/material'

import { displayDayAndDateString } from '../services/dateService';
import { COLOR } from '../siteStyle';

export const AppointmentTableMobile = ({appointments, setSelectedAppointment}) => {
    // Card Layout for Mobile
    return (
      <Grid container spacing={2}>
    {appointments.map((appointment, index) => (
      <Grid item xs={12} key={index}>
        <Card
          elevation={3}
          sx={{ borderRadius: 2, cursor: "pointer" }}
          onClick={() => setSelectedAppointment(appointment)}
        >
          <CardContent>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {appointment.fullname}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Typography>
              <b>Date & Time:</b> {appointment.requestedDate} at{" "}
              {appointment.requestedTime}
            </Typography>
            <Typography>
              <b>Service:</b> {appointment.service}
            </Typography>
            <Typography>
              <b>Status:</b>{" "}
              <span
                style={{
                  color:
                    appointment.status === "Pending"
                      ? "orange"
                      : appointment.status === "Confirmed"
                      ? "green"
                      : COLOR.danger,
                  fontWeight: "bold",
                }}
              >
                {appointment.status}
              </span>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
    )
}